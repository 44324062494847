











































































import { Component, Ref, Vue } from 'vue-property-decorator';
import AppApi from '@/api/application/AppApi';
import CusMenuItem from '@/views/flow-deal/components/cus-menu-item.vue';
import TokenUtil from 'global-ui/packages/utils/TokenUtil';

@Component({
  name: 'FlowInit',
  components: {
    CusMenuItem
  }
})
export default class FlowInit extends Vue {
  // 颜色数组
  colors: any[] = ['rgb(48,199,144)', 'rgb(170,106,250)', 'rgb(252,198,72)', 'rgb(250,104,99)', 'rgb(141, 206, 54)'];

  // 所有数据
  allDataList: any[] = [];

  // 默认选中的应用树
  defaultActiveMenu: string = '';

  // 应用树类型加载状态
  typeTreeLoading: boolean = false;
  // 应用树类型数据
  typeTreeData: any[] = [];

  // 流程列表加载状态
  flowListLoading: boolean = false;
  // 流程列表数据
  flowListData: any[] = [];

  created() {
    this.getTypeTreeData();
  }

  /**
   * 应用类型树点击
   * @param data
   * @param node
   * @param el
   */
  typeTreeNodeClick(data, node, el) {
    if (data.type === '0') {
      // 从所有数据中筛选出 id 相等的数据
      let filter = this.allDataList.filter(item => item.id === data.id);
      if (filter.length > 0) {
        this.flowListData = filter[0].children;
      }
    }

    if (data.type === '1') {
      // 获取应用类型树下指定类型数据
      let typeTreeDataForType = this.getTypeTreeDataForType(this.allDataList, '1');
      if (typeTreeDataForType.length > 0) {
        let filter = typeTreeDataForType.filter(item => item.id === data.id);
        if (filter.length > 0) {
          this.flowListData = [filter[0]];
        }
      }
    }
  }

  /**
   * 删除应用类型树下指定类型数据
   * @param arr
   * @param type
   */
  delTreeNodeType(arr: any[], type: string) {
    if (!arr) return [];
    let idx = 0;
    const list = JSON.parse(JSON.stringify(arr));
    list.forEach((item, index) => {
      if (item.type === type) {
        arr.splice(index - idx, 1);
        idx++;
      } else {
        this.delTreeNodeType(arr[index].children, type);
      }
    });
    return arr;
  }

  /**
   * 获取应用类型树下指定类型数据
   * @param dataList
   * @param type
   */
  getTypeTreeDataForType(dataList: any[], type: string) {
    let res: any[] = [];
    dataList.forEach((item: any) => {
      if (item.type === type) {
        res.push(item);
      }
      if (item.children && item.children.length > 0) {
        res.push(...this.getTypeTreeDataForType(item.children, type));
      }
    });
    return res;
  }

  /**
   * 获取应用类型数据
   */
  getTypeTreeData() {
    this.typeTreeLoading = true;
    AppApi.applicationLinkQueryCodeTree({ linkType: 2 })
      .then((res: any) => {
        if (res.code == 1) {
          this.allDataList = JSON.parse(JSON.stringify(res.data));

          // 左侧流程分类
          let tmp = JSON.parse(JSON.stringify(res.data));
          this.typeTreeData = this.delTreeNodeType(tmp, '1');

          // 流程列表
          this.flowListData = this.getTypeTreeDataForType(res.data, '1');
        }
      })
      .finally(() => {
        this.typeTreeLoading = false;
      });
  }

  /**
   * 返回首页
   */
  goToHome() {
    this.$router.push({ path: '/home' });
  }

  /**
   * 跳转流程
   * @param item
   */
  jumpFlow(item) {
    // let token = TokenUtil.getToken();
    // let url = process.env.VUE_APP_SERVER_CODE + 'task/redirect/create?processId=' + item.id + '&token=' + token;
    // window.location.href = url;

    let path = this.$router.resolve({
        path: "/front2/start",
        query: {
          id: item.id,
        },
      });
      window.open(path.href, "_blank");
  }
}

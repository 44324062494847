var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main" }, [
    _c(
      "div",
      { staticClass: "main-content", staticStyle: { "margin-top": "10px" } },
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c(
              "el-col",
              { attrs: { span: 5, offset: 0 } },
              [
                _c("el-card", { staticClass: "main-card" }, [
                  _c(
                    "div",
                    { staticStyle: { width: "100%", height: "100%" } },
                    [
                      _c("div", { staticStyle: { width: "100%" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "border-bottom": "2px solid #e4e4e4",
                              width: "100%",
                              height: "30px",
                              "font-size": "var(--rootFontSize2)",
                              "font-weight": "900",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("lang_process_classification"))
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticStyle: { height: "100%", width: "100%" } },
                        [
                          _vm.typeTreeData.length === 0
                            ? _c("el-empty", {
                                staticStyle: { height: "calc(100% - 50px)" },
                                attrs: { description: _vm.$t("lang_no_data") },
                              })
                            : _vm._e(),
                          false
                            ? _c("el-tree", {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.typeTreeLoading,
                                    expression: "typeTreeLoading",
                                  },
                                ],
                                attrs: {
                                  data: _vm.typeTreeData,
                                  "empty-text": _vm.$t("lang_no_data"),
                                  "node-key": "id",
                                  indent: 0,
                                  "default-expand-all": "",
                                  props: { label: "text" },
                                  "expand-on-click-node": false,
                                },
                                on: { "node-click": _vm.typeTreeNodeClick },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var node = ref.node
                                        var data = ref.data
                                        return [
                                          _c(
                                            "div",
                                            { staticStyle: { width: "100%" } },
                                            [
                                              data.type == 0
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "#3f9eff",
                                                        "text-align": "center",
                                                        width: "100%",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "white",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(node.label)
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        border:
                                                          "2px solid #3f9eff",
                                                        "text-align": "center",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "black",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(node.label)
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1148021590
                                ),
                              })
                            : _c(
                                "el-menu",
                                {
                                  staticStyle: { height: "calc(100% - 50px)" },
                                  attrs: {
                                    "background-color": "#fff",
                                    "text-color": "#34A1FF",
                                    "active-text-color": "#000",
                                  },
                                },
                                [
                                  _c("cus-menu-item", {
                                    attrs: { "menu-list": _vm.typeTreeData },
                                    on: {
                                      changeAppType: _vm.typeTreeNodeClick,
                                    },
                                  }),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ],
              1
            ),
            _c(
              "el-col",
              { attrs: { span: 19, offset: 0 } },
              [
                _c("el-card", { staticClass: "main-card" }, [
                  _c(
                    "div",
                    { staticClass: "app-container" },
                    [
                      _vm.flowListData.length === 0
                        ? _c("el-empty", { attrs: { description: "" } })
                        : _c(
                            "el-row",
                            { attrs: { gutter: 10 } },
                            _vm._l(_vm.flowListData, function (item) {
                              return _c(
                                "el-col",
                                { key: item.id, attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-card",
                                    {
                                      staticClass: "apptype-card",
                                      attrs: { shadow: "hover" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "apptype-card-header",
                                          style: {
                                            borderTopColor:
                                              _vm.colors[
                                                Math.round(
                                                  Math.random() *
                                                    _vm.colors.length
                                                )
                                              ],
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.text)),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              " (" +
                                                _vm._s(item.children.length) +
                                                ")"
                                            ),
                                          ]),
                                        ]
                                      ),
                                      item.children.length > 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "apptype-card-body",
                                            },
                                            _vm._l(
                                              item.children,
                                              function (row) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: row.id,
                                                    staticClass: "app-item",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.jumpFlow(row)
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "app-item-label",
                                                      },
                                                      [_vm._v(_vm._s(row.text))]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
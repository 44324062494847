<template>
  <div>
    <template v-for='(item,index) in menuList'>
      <el-menu-item v-if='!item.children || item.children.length==0' :index='item.id'>
        <div slot='title' @click='open(item)'>{{ item.text }}</div>
      </el-menu-item>
      <el-submenu v-else :index='item.id'>
        <template slot='title'>
          <div @click='open(item)'>{{ item.text }}</div>
        </template>
        <template v-for='(child,cindex) in item.children'>
          <el-menu-item v-if='!child.children || child.children.length==0' :index='child.id'>
            <div slot='title' @click='open(child)'>{{ child.text }}</div>
          </el-menu-item>
          <cus-menu-item v-else :menu-list='[child]' @changeAppType='open'></cus-menu-item>
        </template>

      </el-submenu>
    </template>
  </div>
</template>

<script>
export default {
  name: 'CusMenuItem',
  props: {
    menuList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {};
  },
  methods: {
    // 点击菜单项
    open(item) {
      this.$emit('changeAppType', item);
    }
  },
  created() {

  }
};
</script>

<style scoped>

</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.menuList, function (item, index) {
        return [
          !item.children || item.children.length == 0
            ? _c("el-menu-item", { attrs: { index: item.id } }, [
                _c(
                  "div",
                  {
                    attrs: { slot: "title" },
                    on: {
                      click: function ($event) {
                        return _vm.open(item)
                      },
                    },
                    slot: "title",
                  },
                  [_vm._v(_vm._s(item.text))]
                ),
              ])
            : _c(
                "el-submenu",
                { attrs: { index: item.id } },
                [
                  _c("template", { slot: "title" }, [
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.open(item)
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.text))]
                    ),
                  ]),
                  _vm._l(item.children, function (child, cindex) {
                    return [
                      !child.children || child.children.length == 0
                        ? _c("el-menu-item", { attrs: { index: child.id } }, [
                            _c(
                              "div",
                              {
                                attrs: { slot: "title" },
                                on: {
                                  click: function ($event) {
                                    return _vm.open(child)
                                  },
                                },
                                slot: "title",
                              },
                              [_vm._v(_vm._s(child.text))]
                            ),
                          ])
                        : _c("cus-menu-item", {
                            attrs: { "menu-list": [child] },
                            on: { changeAppType: _vm.open },
                          }),
                    ]
                  }),
                ],
                2
              ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }